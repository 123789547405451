import { useEffect, useState } from 'react'
import { Table, Button, Row, Col, ConfigProvider, Select, Tag, theme, Avatar } from 'antd';
import { PlusCircleOutlined, UserOutlined } from "@ant-design/icons";
import axios from '../settings/axios.js'
import Head from "./Head.jsx";
import { useNavigate } from 'react-router-dom';
import { geekblue, green, red, } from '@ant-design/colors';

export default function TableUser() {
  const navigate = useNavigate()
  const [users, setUsers] = useState([])
  const [search, setSearch] = useState('None')
  const [searchElem, setSearchElem] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [isLoadingSearch, setLoadingSearch] = useState(true)

  const columns = [
    {
      title: 'Айди',
      dataIndex: 'id',
    },
    {
      title: 'Ник',
      dataIndex: 'nick',
      render: (nick, record) => {
        const color = record.dostup == 1?geekblue[4]:record.dostup == 2?green[5]:record.dostup == 3?red[4]:'White'
        return (
        <div>
          {record.avatar == '' ? <Avatar icon={<UserOutlined />} style={{ marginRight: 8 }} /> : <Avatar src={record.avatar} style={{ marginRight: 8 }} /> }
          <span style={{ color: color, textShadow: `${color} 1px 1px 10px` }}>{nick}</span>
        </div>
        )
      }
    },
    {
      title: 'Уровень доступа',
      dataIndex: 'dostup',
      render:(tag)=>{
        const color = tag == 1?'geekblue':tag == 2?'success':tag == 3?'error':'White'
        const accessLvl = tag == 1?'Пользователь':tag == 2?'Управляющий состав':tag == 3?'Член совета директоров':'Член совета директоров'
        return <Tag style={{textShadow: `${color} 1px 1px 10px`, fontWeight:"bold"}} color={color} key={tag} dataIndex='rank'>{accessLvl}</Tag>
      }
    },
    {
      title: 'Должность',
      dataIndex: 'rank',
    },
    {
      title: 'Предупреждения',
      dataIndex: 'warn',
    },
    {
      title: 'Выговоры',
      dataIndex: 'vig',
    },
  ];

  useEffect(() => {
    axios.get('/api/checkauth').then((auth) => {
      axios.get(`/api/userinfo/${auth.data.userId}`).then((response) => {
        let resArray = []
        const serverArray = response.data.server.split(' ')
        for (let i = 0; i < serverArray.length; i++) {
          resArray.push({ value: serverArray[i], label: serverArray[i] })
        }
        setSearchElem(resArray)
        setLoadingSearch(false)
      })
    })
  }, [])

  useEffect(() => {
    if(search !== 'None') {
      setLoading(true)
      setLoadingSearch(true)
      axios.post('/api/users', { server: search }).then((response) => {
        const sortUsers = [...response.data].sort((a, b) => b.dostup - a.dostup)
        const resUsers = []
        for (let i = 0; i < sortUsers.length; i++) {
          if(sortUsers[i].block != 1) {
            resUsers.push({ id: sortUsers[i].id, nick: sortUsers[i].nick, dostup: sortUsers[i].dostup, rank: sortUsers[i].rank, vig: sortUsers[i].vig, warn: sortUsers[i].warn, avatar: sortUsers[i].avatar })
          }
        }
        setUsers(resUsers)
        if(resUsers.length > 0) {
          setLoading(false)
          setLoadingSearch(false)
        }
      })
    }
  }, [search])

  return (
    <div>
        <Head />
            <ConfigProvider
                theme={{
                  algorithm: theme.darkAlgorithm,
                  token: {
                      colorSplit: "#ffffff",
                      colorBgBase: "#141414",
                  },
                  components: {
                    Table: {
                      headerBg:"#222222",
                      headerColor: "#ffffff",
                      headerSplitColor: "#222222",
                      borderColor: "#141414",
                      borderRadius: "50px",
                      rowHoverBg: "#121212"
                    }
                  },
                }}
              >
              <Row align="middle" justify="center">
              <Col xs={24} sm={12} md={8} lg={19} xl={20} style={{padding:"50px"}}>
              <div className="employee-nav">
                <Button type='primary' icon={<PlusCircleOutlined/>} className="add-button">Добавить</Button>
                <Select
                  className="select-box"
                  style={{ width: 155 }}
                  placeholder="Выберите сервер"
                  onChange={setSearch}
                  options={searchElem}
                  disabled={isLoadingSearch}
                  loading={isLoadingSearch}
                />
              </div>
              <Table
              style={{marginTop:"12px"}}
              columns={columns}
              dataSource={users}
              size="middle"
              pagination={true}
              loading={isLoading}
              rowKey={ (record) => record.id }
              onRow={(record) => {
                return {
                  onClick: () => navigate(`/user/${record.id}`)
                }
              }}/>
              </Col>
              </Row>
            </ConfigProvider>
    </div>
  )
}