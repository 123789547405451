import { Link, useNavigate } from "react-router-dom";
import axios from '../settings/axios.js'

import {
    HomeOutlined,
    LogoutOutlined,
    ShopOutlined,
    PaperClipOutlined,
    SettingOutlined
} from "@ant-design/icons";
import { Layout, Menu, Button } from "antd";

const { Header } = Layout;

export default function Head() {
    const navigate = useNavigate()

    const handleLogout = async () => {
        try {
            axios.post('/api/logout').then(() => {
                navigate('/login')
            })
        } catch (e) {
            console.error('Ошибка при выходе:', e);
        }
    }

    return (
        <div>
            <Layout className="layout">
                <Header style={{ display: 'flex', justifyContent: 'space-between', background: "#141414" }}>
                <div className="logo" style={{ color: 'white' }}><a href="/"><h1>Forbes<span className="primary-span">Beta</span></h1></a></div>
                <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']} style={{background: "#141414"}}>
                    <Link to="/"><Menu.Item key="1" icon={<HomeOutlined />}>Главная</Menu.Item></Link>
                    <Link to="/shop"><Menu.Item key="2" icon={<ShopOutlined />}>Магазин</Menu.Item></Link>
                    <Link to="/settings"><Menu.Item key="3" icon={<PaperClipOutlined />}>Посты</Menu.Item></Link>
                    <Link to="/settings"><Menu.Item key="3" icon={<SettingOutlined />}>Настройки</Menu.Item></Link>
                </Menu>
                <div>
                    <Button onClick={handleLogout} type="primary" danger style={{ marginRight: '10px' }} icon={<LogoutOutlined />}>Выход</Button>
                </div>
                </Header>
            </Layout>
      </div>
    )
}