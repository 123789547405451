import Head from "./Head.jsx";
import { Row, Col } from 'antd';


export default function Settings() {
    return (
        <div>
            <Head />
            <Row align="middle" justify="center" style={{ minHeight: "100vh", marginTop:"-70px" }}>
                <Col>
                <h1 style={{ display: "flex" }}>В разработке</h1>   
                </Col>
            </Row>
        </div>
    )
}