import React, { useEffect } from "react";
import Employee from "./components/employee.jsx";
import LoginPage from './components/loginPage.jsx'
import Shop from "./components/Shop.jsx";
import Settings from "./components/Settings.jsx";
import User from "./components/user.jsx";
import PrivateRoute from "./routes/privateRoute.jsx";

import { Routes, Route, BrowserRouter } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route exact path="/" element={<Employee/>} />
            <Route exact path="/shop" element={<Shop/>} />
            <Route exact path="/shop" element={<Shop/>} />
            <Route exact path="/user/:id" element={<User/>} />
            <Route exact path="/settings" element={<Settings/>} />
          </Route>
          <Route exact path="/login" element={<LoginPage/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
