import { useEffect, useState } from "react";
import axios from '../settings/axios.js'
import Head from "./Head.jsx";
import { Row, Col, Card, ConfigProvider, Button, Modal, Form, Select, Table, Tag, notification, theme } from 'antd';
import { 
    FieldTimeOutlined,
    CloseCircleOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

export default function Settings() {
    const navigate = useNavigate()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedServer, setSelectedServer] = useState('')
    const [shopBuy, setShopBuy] = useState([])
    const [isLoadingSearch, setLoadingSearch] = useState(true)
    const [searchElem, setSearchElem] = useState([])
    const [search, setSearch] = useState('None')
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedValue, setSelectedValue] = useState('');
    const [user, setUser] = useState({});

    const [api, contextHolder] = notification.useNotification();
    const notifyErorr = (title, text) => {
      api.error({
        message: title,
        description: text,
      });
    };
    const notifySuccess = (title, text) => {
        api.success({
          message: title,
          description: text,
        });
    };

    const showModal = async (record) => {
        if(user.dostup >= 2) {
            setSelectedRow(record);
            setIsModalVisible(true);
        }
    };
    
    const handleOk = () => {
        if (selectedRow) {
            console.log(`${selectedRow.id}\n${selectedValue}`)
            axios.post('/api/closeditemshop', { id: selectedRow.id, status: selectedValue, item: selectedRow.action, nickname: selectedRow.nickname }).then((info) => {
                if(info.data.error) {
                    notifyErorr('Ошибка', info.data.message)
                } else {
                    setShopBuy(prevData => {
                        return prevData.map(item => {
                          if (item.id === selectedRow.id) {
                            return { ...item, status: selectedValue };
                          }
                          return item;
                        });
                    });
                    notifySuccess('Успешно!', info.data.message)
                }
            })
        }
        setIsModalVisible(false);
    };
    
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleSelectChange = (value) => {
        setSelectedValue(value);
        console.log(`Selected value: ${value}`);
    };

    const columns = [
        {
            title: 'ID Заказа',
            dataIndex: 'id',
        },
        {
          title: 'Айди',
          dataIndex: 'userId',
        },
        {
          title: 'Ник',
          dataIndex: 'nickname',
        },
        {
            title: 'Запрос',
            dataIndex: 'action',
            render: (text) => text == 'unpred'?'Снятие предупреждения':text == 'unvig'?'Cнятие выговора':'none'
        },
        {
          title: 'Статус',
          dataIndex: 'status',
          render: (tag) => {
            const color = tag === "Принято" ? 'green' : tag === "Отказано" ? 'red' : tag === "Ожидается" ? 'orange' : 'orange';
            const icon = tag === 'Принято' ? <CheckCircleOutlined /> : tag == 'Отказано' ? <CloseCircleOutlined /> : tag === 'Ожидается' ? <FieldTimeOutlined /> : <FieldTimeOutlined />
            return <Tag style={{ fontSize: '13px', fontWeight: "bold" }} color={color} key={tag} dataIndex='rank'>{icon} {tag}</Tag>;
            },
        },
        {
            title: 'Дата',
            dataIndex: 'data',
        },
    ];

    useEffect(() => {
        axios.get('/api/checkauth').then((auth) => {
          axios.get(`/api/userinfo/${auth.data.userId}`).then((response) => {
            let resArray = []
            const serverArray = response.data.server.split(' ')
            for (let i = 0; i < serverArray.length; i++) {
              resArray.push({ value: serverArray[i], label: serverArray[i] })
            }
            setSearchElem(resArray)
            setLoadingSearch(false)
          })
        })
    }, [])

    useEffect(() => {
        axios.get('/api/checkauth').then((auth) => {
          axios.get(`/api/userinfo/${auth.data.userId}`).then((response) => {
            setUser({ userId: response.data.idn, dostup: response.data.dostup })
          })
        })
    }, [])


    useEffect(() => {
        if(search !== 'None') {
            axios.post('/api/shop', { server: search }).then((response) => {
                const data = response.data;
                if(data.length > 0) {
                    let resData = [];
        
                    // Отсортируем данные по isActive
                    const sortedData = data.sort((a, b) => {
                        if (a.isActive === b.isActive) {
                            return 0;
                        } else if (a.isActive) {
                            return -1;
                        } else {
                            return 1;
                        }
                    });
            
                    // Преобразуем данные в нужный формат
                    sortedData.forEach((data) => {
                        resData.push({ id: data.id, userId: data.userId, nickname: data.nickname, action: data.action, status: data.status, data: data.data });
                    });
            
                    setShopBuy(resData);
                }
            });
        }
    }, [search]);

    const predBuy = () => {
        if(search == 'None') {
            notifyErorr('Вы не выбрали сервер', 'Пожалуйста выберите сервер перед тем как покупать услугу!')
        } else {
            axios.post('/api/addshop', {"action": "unpred", "server": search}).then((response) => {
                if(response.data.error) {
                    notifyErorr('Ошибка', response.data.message)
                } else {
                    setShopBuy([response.data, ...shopBuy]);
                    notifySuccess('Покупка совершена', 'Вы успешно создали запрос на покупку, ожидайте рассмотрения')
                }
            })
        }
    };

    const vigBuy = () => {
        if(search == 'None') {
            notifyErorr('Вы не выбрали сервер', 'Пожалуйста выберите сервер перед тем как покупать услугу!')
        } else {
            axios.post('/api/addshop', {"action": "unvig", "server": search}).then((response) => {
                if(response.data.error) {
                    notifyErorr('Ошибка', response.data.message)
                } else {
                    setShopBuy([response.data, ...shopBuy]);
                    notifySuccess('Покупка совершена', 'Вы успешно создали запрос на покупку, ожидайте рассмотрения')
                }
            })
        }
    };

    return (
        <div>
            <Head />
            <ConfigProvider
                theme={{
                    algorithm: theme.darkAlgorithm,
                    token: {
                        colorSplit: "#ffffff",
                        colorBgBase: "#141414",
                    },
                    components: {
                        Table: {
                            headerBg: "#222222",
                            headerColor: "#ffffff",
                            headerSplitColor: "#222222",
                            borderColor: "#141414",
                            borderRadius: "50px",
                            rowHoverBg: "#121212",
                        },
                        Card: {
                            headerBg: "#141414",
                            actionsBg: "#ffffff",
                        },
                        Modal: {
                            titleColor: "#ffffff",
                            contentBg: "#141414",
                            headerBg: "#141414",
                            hoverBg: "#ffffff",
                        },
                    },
                }}
            >
                {contextHolder}
                <div style={{ marginTop: "50px" }}>
                    <Row gutter={22} justify="center">
                        <Col xs={24} sm={12} md={8} lg={6} xl={5}>
                            <Card title="Снять предупреждение">
                                <h1 style={{ marginBottom: "20px" }}>
                                    <span className="primary-span">25</span> Баллов
                                </h1>
                                <Button onClick={predBuy} style={{ width: '100%', maxWidth: '80px' }}>
                                    Купить
                                </Button>
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={5}>
                            <Card title="Снять выговор">
                                <h1 style={{ marginBottom: "20px" }}>
                                    <span className="primary-span">40</span> Баллов
                                </h1>
                                <Button onClick={vigBuy} style={{ width: '100%', maxWidth: '80px' }}>
                                    Купить
                                </Button>
                            </Card>
                        </Col>
                    </Row>
                    <Row align="middle" justify="center" style={{ marginTop: "50px" }}>
                        <Col xs={24} sm={20} md={16} lg={12} xl={20}>
                            <div className="shop-nav">
                                <Select
                                    className="select-box"
                                    style={{ width: 155 }}
                                    placeholder="Выберите сервер"
                                    onChange={setSearch}
                                    options={searchElem}
                                    loading={isLoadingSearch}
                                />
                            </div>
                            <Table
                                columns={columns}
                                dataSource={shopBuy}
                                loading={isLoadingSearch}
                                rowKey={(record) => record.id}
                                onRow={(record) => ({
                                    onClick: (() => showModal(record)),
                                })}
                            />
                            <Modal
                                title="Выберите ответ"
                                visible={isModalVisible}
                                onOk={handleOk}
                                onCancel={handleCancel}
                                okText="Выбрать ответ"
                                cancelText="Отмена"
                                width="280px"
                            >
                                <Select
                                    style={{ width: "100%" }}
                                    onChange={handleSelectChange}
                                    placeholder='Выберите ответ'
                                    options={[{ value: 'Принято', label: 'Принято' }, { value: 'Отказано', label: 'Отказано' }]}
                                />
                            </Modal>
                        </Col>
                    </Row>
                </div>
            </ConfigProvider>
        </div>
    );
}