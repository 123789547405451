import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { formatLogs } from '../settings/logsFormatter.js';
import { geekblue, green, red } from '@ant-design/colors';
import { UserOutlined } from '@ant-design/icons';
import { Chart as ChartJS } from 'chart.js/auto'
import { Bar, Doughnut, Line } from 'react-chartjs-2'

import {
  Row,
  Card,
  ConfigProvider,
  Col,
  Button,
  Flex,
  Modal,
  Input,
  Form,
  Divider,
  Space,
  Table,
  theme,
  Watermark,
  Avatar,
  Typography,
  Badge,
  Tag
} from 'antd';

import Head from './Head.jsx';
import axios from '../settings/axios.js';

const { Text, Title } = Typography;
const { Meta } = Card;

export default function User() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingLogs, setLoadingLogs] = useState(true);
  const [user, setUser] = useState(null);
  const [logs, setLogs] = useState([]);
  const [posts, setPosts] = useState([]);
  const params = useParams();
  const id = params.id;

  const sortPosts = posts.map(item => item.posts);

  const options = {
    maintainAspectRatio: false, // Отключаем сохранение пропорций, чтобы график мог подстраиваться под размер контейнера
    scales: {
      x: {
        type: 'category', // Указываем тип шкалы как 'category' для горизонтальной оси
      },
      y: {
        type: 'linear', // Указываем тип шкалы как 'linear' для вертикальной оси
        beginAtZero: true,
      },
    },
  };


  useEffect(() => {
    axios.get(`/api/userinfo/${id}`).then((response) => {
      const userData = response.data;
      setUser(userData);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    axios.get(`/api/mylogs/${id}`).then((response) => {
      const logsData = response.data;
      const formattedLogs = formatLogs(logsData); // Используем функцию formatLogs

      if (formattedLogs.length > 0) {
        setLogs(formattedLogs);
      } else {
        setLogs(['Информация о последних логах для данного пользователя отсутствует!']);
      }
    });
  }, []);

  useEffect(() => {
    if (user && user.nickname) {
      axios.get(`/api/week?nick=${user.nickname}&days=${getDaysParam()}`).then((response) => {
        const res = response.data;
        const week = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];
        let resArray = [];

        // Получаем текущий день недели
        const currentDate = new Date();
        const currentDayOfWeek = currentDate.getDay();

        // Создаем массив с нулевыми значениями для всех дней недели
        const postsThisWeek = week.map(day => ({ day, posts: 0 }));

        // Заполняем массив данными из ответа API
        res.forEach(item => {
          const itemDate = new Date(item.data);
          const dayOfWeek = itemDate.getDay();
          const dayIndex = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
          const postCount = item.posts;

          postsThisWeek[dayIndex].posts = postCount;
        });

        // Устанавливаем результат
        setPosts(postsThisWeek);
        setLoadingLogs(false);
      });
    }

    function getDaysParam() {
      const currentDate = new Date();
      const daysParam = [];
      for (let i = 0; i < 7; i++) {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + i);
        const dayOfWeek = date.getDay();
        daysParam.push(dayOfWeek === 0 ? 7 : dayOfWeek); // Понедельник = 1, Воскресенье = 7
      }
      return daysParam.join(',');
    }
  }, [user]);

  useEffect(() => {
    console.log(posts)
  }, [posts])

  const columns = [
    {
      title: 'День недели',
      dataIndex: 'day',
      key: 'day',
    },
    {
      title: 'Количество постов',
      dataIndex: 'posts',
      key: 'posts',
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Head />
      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
          token: {
            colorSplit: '#ffffff',
            colorBgBase: '#141414',
          },
          components: {
            Card: {
              headerBg: '#141414',
              actionsBg: '#ffffff',
            },
            Modal: {
              titleColor: '#ffffff',
              contentBg: '#141414',
              headerBg: '#141414',
              hoverBg: '#ffffff',
            },
          },
        }}
      >
        <div style={{ marginTop: '100px' }}>
          <Row gutter={16} typeof='flex' justify="center">
            <Col xs={24} sm={24} md={10} lg={10} xl={10} className='gutter-row'>
            <Card className="users-info" bodyStyle={{ height: '300px' }} title="Информация о пользователе" loading={isLoading}>
              {user ? (
                <>
                <Row align="middle">
                  <Col>
                    {user.avatar !== "" ? 
                    (<>
                     <Avatar size={150} icon={<UserOutlined />} src={user.avatar} />
                    </>) : 
                    (<>
                     <Avatar size={150} icon={<UserOutlined />} />
                    </>)}
                  </Col>
                  <Col flex="auto" style={{ marginLeft: 16 }}>
                    <Title level={3} style={{ marginBottom: 4, color: user.dostup == 1 ? geekblue[4] : user.dostup == 2 ? green[5] : user.dostup == 3 ? red[4] : red[4], textShadow: `${user.dostup == 1 ? geekblue[4] : user.dostup == 2 ? green[5] : user.dostup == 3 ? red[4] : red[4]} 1px 1px 10px`, }}>{user.nickname}</Title>
                    <Text style={{ color: '#8c8c8c'}}>{user.rank}</Text>
                    <Tag color="green" style={{ marginLeft: 5 }}>{user.server}</Tag>
                  </Col>
                </Row>
                <Divider style={{ marginTop: 30 }}></Divider>
                <Row justify="space-around" style={{ marginTop: 16 }}>
                  <Col>
                    <Text>Выговоры</Text>
                    <div style={{ color: '#8c8c8c' }}>{user.vig}/3</div>
                  </Col>
                  <Col>
                    <Text>Предупреждения</Text>
                    <div style={{ color: '#8c8c8c' }}>{user.warn}/2</div>
                  </Col>
                  <Col>
                    <Text>Баллы</Text>
                    <div style={{ color: '#8c8c8c' }}>{user.balls}/1000</div>
                  </Col>
              </Row>
              </>
              ) : (
                <div>Loading...</div>
              )}
            </Card>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={10} className='gutter-row'>
              <div className="week-stats">
                <Card bodyStyle={{ height: '300px' }} title="Статистика за неделю" className="weekly-stats-card" loading={isLoadingLogs}>
                  <Bar 
                    data={{
                      labels: ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'],
                      datasets: [
                        {
                          label: 'Статистика за неделю',
                          borderRadius: 5,
                          data: sortPosts,
                          borderWidth: 1,
                        },
                      ]
                    }}
                    options={options}/>
                </Card>
              </div>
            </Col>
          </Row>
          <Row typeof='flex' justify="center">
            <Col xs={24} sm={24} md={20} lg={20} xl={20}>
              <div className='users-logs'>
                <Card title="Лог последних действий" loading={isLoadingLogs}>
                  {logs.map((item, index) => (
                    <p key={index}>{item}</p>
                  ))}
                </Card>
              </div>
              <Divider style={{ color: '#ffffff' }} orientation="left">Действия</Divider>
              <div className='users-button'>
                <Flex wrap gap="small" className='site-b'>
                  <Button type='primary'>Редактировать</Button>
                  <Button onClick={showModal} type='primary' danger ghost>Выдать предупреждение</Button>
                  <Button type='primary' danger ghost>Выдать выговор</Button>
                </Flex>
              </div>
            </Col>
          </Row>
        </div>
      </ConfigProvider>
    </div>
  );
}