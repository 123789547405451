import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import axios from "../settings/axios.js";
import { Progress } from 'antd';

const PrivateRoute = () => {
    const [isAuth, setIsAuth] = useState(null);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const res = await axios.get('/api/checkauth');
                setIsAuth(res.data.valid);
            } catch (error) {
                console.error("Authentication check failed:", error);
                setIsAuth(false);
            }
        };

        checkAuth();
    }, []);

    if (isAuth === null) {
        return <div>Loading...</div>;
    }

    return (
        isAuth ? <Outlet /> : <Navigate to="/login" />
    );
};

export default PrivateRoute;