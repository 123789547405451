const formatLog = (log) => {
    let message;
    switch (log.type) {
        case 'balls':
            message = `[${log.data}] ${log.user} установлено ${log.action} баллов по запросу ${log.admin} Причина: ${log.reason}`;
            break;
        case 'pred':
            message = `[${log.data}] ${log.user} выдано ${log.action} предупреждение по запросу ${log.admin} Причина: ${log.reason}`;
            break;
        case 'unpred':
            message = `[${log.data}] ${log.user} снято ${log.action} предупреждение по запросу ${log.admin} Причина: ${log.reason}`;
            break;
        case 'vig':
            message = `[${log.data}] ${log.user} выдан ${log.action} выговор по запросу ${log.admin} Причина: ${log.reason}`;
            break;
        case 'unvig':
            message = `[${log.data}] ${log.user} снят ${log.action} выговор по запросу ${log.admin} Причина: ${log.reason}`;
            break;
        case 'rank':
            message = `[${log.data}] ${log.admin} изменил ранг пользователю ${log.user} на ${log.action}`;
            break;
        case 'block':
            message = `[${log.data}] ${log.admin} заблокировал аккаунт ${log.user}`;
            break;
        case 'unblock':
            message = `[${log.data}] ${log.admin} разблокировал аккаунт ${log.user}`;
            break;    
        case 'server':
            message = `[${log.data}] ${log.admin} выдал доступ к серверам ${log.action} пользователю ${log.user}`;
            break;    
        case 'closedItem':
            message = `[${log.data}] ${log.admin} отказал тикет в магазине от ${log.user} Причина: Отказ`
            break;
        default:
            message = '';
    }
    return message;
};

const formatLogs = (logs) => {
    return logs.slice(0, 10).map((log) => formatLog(log));
};

export { formatLogs };