import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Card, Space, Form, Input, Row, Col, Button, ConfigProvider, notification, theme } from 'antd';
import axios from '../settings/axios.js';

export default function Login() {
    const navigate = useNavigate()
    const appID = 52043492
    const [errorMessage, setErrorMessage] = useState('')

    const [api, contextHolder] = notification.useNotification();
    const openNotificationError = (placement, message) => {
        api.error({
        message: `Ошибка`,
        description: message,
        placement,
        });
    };

    useEffect(() => {
        if(errorMessage != '') {
            openNotificationError('top', errorMessage)
        }
    }, [errorMessage])

  useEffect(() => {
    // Инициализация объекта VK
    window.VK.init({
      apiId: appID // Ваш идентификатор приложения VK
    });

    // Создание виджета авторизации
    const vkWidget = window.VK.Widgets.Auth("vk_auth_container", {
      width: "300px",
      mode: "popup", // или "widget" для отображения на странице
      onAuth: (response) => {
        console.log(response)
        axios.post('/api/login', { id: response.uid }).then((res) => {
          console.log(res.data)
          if(res.data.auth) {
            navigate('/')
          } else {
            setErrorMessage(res.data.message)
          }
        })
      },
      settings: {
        appId: appID, // Ваш идентификатор приложения VK
        scope: "email", // Разрешения, которые вы хотите запросить
      },
    });
  }, []);

  return (
    <div>
        <ConfigProvider
            theme={{
                algorithm: theme.darkAlgorithm,
                token: {
                    colorSplit: "#ffffff",
                    colorBgBase: "#141414",
                },
                components: {
                Card: {
                    headerBg: "#141414",
                    actionsBg: "#ffffff"
                },
                Form: {
                    labelRequiredMarkColor: "#141414"
                },
                },
            }}
            >
            {contextHolder}
            <Row typeof='flex' justify="center" align="middle" style={{minHeight:"100vh"}}>
                <Col>
                    <Space direction="vertical" size={16}>
                        <Card
                        title="Авторизация"
                        >
                        <div>
                            {/* Использование inline-стилей для изменения размера виджета */}
                            <div id="vk_auth_container" style={{ marginBottom: "6px" }}></div>
                        </div>
                        <p style={{ textAlign: 'center', color: "#ffffff" }}><a href="https://arz-forbes.ru/api/alternativeAuth">Альтернативная авторизация</a></p>
                        </Card>
                    </Space>
                </Col>
            </Row>
        </ConfigProvider>
    </div>
  );
}